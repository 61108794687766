import { Button, Form, Spin } from 'antd';
import AppResult from '../common/AppResult';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTest, putTest } from '../../services/testService';
import TestInfo from './TestInfo';
import TestContent from './TestContent';
import { postImage } from '../../services/imageService';

export default function UpdateTest() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getTest(location.state.id).then((response) => {
      response.testCategoryTitle = response.testCategory.title;
      response.testQuestionGroups.map((g) =>
        g.testQuestions.sort((a, b) => a.position - b.position)
      );
      setData(response);
    });
  }, [location]);

  const onFinish = async (values) => {
    values.newImages = [];
    if (typeof values.coverImageData == 'object') {
      values.coverImageData = await postImage(
        'test',
        values.coverImageData[0].originFileObj
      );
      values.newImages.push(values.coverImageData);
    }
    if (typeof values.firstScreenImageData == 'object') {
      values.firstScreenImageData = await postImage(
        'test',
        values.firstScreenImageData[0].originFileObj
      );
      values.newImages.push(values.firstScreenImageData);
    }

    let questionCount = 0;
    for (let group of values.testQuestionGroups) {
      questionCount += group.testQuestions.length;
      group.minValue = group.baseValue;
      group.maxValue = group.baseValue;
      delete group.id;
      for (let question of group.testQuestions) {
        delete question.id;
        let weightOptions = question.testOptions.map((q) => q.weight);
        group.minValue += Math.min(...weightOptions);
        group.maxValue += Math.max(...weightOptions);
        for (let option of question.testOptions) {
          delete option.id;
        }
      }
      for (let inter of group.testInterpretations) {
        delete inter.id;
      }
    }
    values.questionCount = questionCount;

    putTest(data.id, values).then((response) => {
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Тест «${values.name}» изменен.`,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  if (!data) return <Spin />;

  return (
    <>
      {!responseResult && (
        <>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Редактирование
          </h1>
          <Form form={form} initialValues={data} onFinish={onFinish}>
            <TestInfo
              coverImage={data.coverImageData}
              firstScreenImage={data.firstScreenImageData}
            />
            <TestContent form={form} groups={data.testQuestionGroups} />
            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  navigate('/test');
                }}
                style={{ marginRight: '2rem', width: '95px' }}
              >
                Назад
              </Button>
              <Button htmlType='submit' type='primary'>
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {responseResult && (
        <AppResult data={responseResult} pathSection={'/test'} />
      )}
    </>
  );
}
