import { Button, Form, Spin } from 'antd';
import AppResult from '../common/AppResult';
import { useState } from 'react';
import TestInfo from './TestInfo';
import TestContent from './TestContent';
import { postTest } from '../../services/testService';
import { postImage } from '../../services/imageService';

export default function CreateTest() {
  const [form] = Form.useForm();
  const [responseResult, setResponseResult] = useState();
  const [loading, setLoading] = useState(false);

  const clearData = () => {
    form.resetFields();
    setResponseResult(null);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.coverImageData = await postImage(
      'test',
      values.coverImageData[0].originFileObj
    );
    values.firstScreenImageData = await postImage(
      'test',
      values.firstScreenImageData[0].originFileObj
    );

    let questionCount = 0;
    for (let group of values.testQuestionGroups) {
      questionCount += group.testQuestions.length;
      group.minValue = group.baseValue;
      group.maxValue = group.baseValue;
      if (typeof group.main != 'boolean') {
        group.main = false;
      }
      for (let question of group.testQuestions) {
        let weightOptions = question.testOptions.map((q) => q.weight);
        group.minValue += Math.min(...weightOptions);
        group.maxValue += Math.max(...weightOptions);
      }
    }
    values.questionCount = questionCount;

    postTest(values).then((response) => {
      setLoading(false);
      if (response?.id) {
        setResponseResult({
          status: 'success',
          title: 'Отлично!',
          subtitle: `Тест «${values.title}» добавлен в информационную базу.`,
          additionalButtonText: 'Добавить еще',
          additionalLogic: clearData,
        });
      } else {
        setResponseResult({
          status: 'error',
          title: 'Ошибка',
          subtitle: response,
          additionalButtonText: 'Назад',
          additionalLogic: () => setResponseResult(null),
        });
      }
    });
  };

  return (
    <>
      {!responseResult && (
        <Spin spinning={loading} delay={500}>
          <h1 style={{ textAlign: 'left', marginTop: '0px' }}>
            Создание теста
          </h1>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ testQuestionGroups: [{ name: 'Основная' }] }}
          >
            <TestInfo />
            <TestContent form={form} />
            <Form.Item>
              <Button htmlType='submit' type='primary'>
                Создать
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      )}
      {responseResult && (
        <AppResult
          data={responseResult}
          pathSection={'/test'}
          additionalLogic={clearData}
        />
      )}
    </>
  );
}
